import { formatDate } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Skeleton } from "@mui/material";
import { APP_V2_APP_EVENTS, APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { differenceInDays, parseISO } from "date-fns";
import pluralize from "pluralize";
import { type ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getIsExpiringSoon } from "../../utils";
import { AccountsRequirementCard } from "../Components";
import { PROFESSIONAL_REFERENCE_REQUIREMENT_ID, REQUIREMENT_STATUS_DATA } from "../constants";
import { useGetDocumentDetailsRoute } from "../hooks/useGetDocumentDetailsRoute";
import { DocumentRequirementType, type HcpRequirement } from "../types";

interface AccountsRequirementProps {
  requirement: HcpRequirement;
  requirementType: DocumentRequirementType;
  potentialShiftsAndFacilitiesCounts?: {
    potentialShiftsCount: number;
    potentialFacilitiesCount: number;
  };
  documentsDropOffFlowId: string;
  qualification?: string;
}

export function AccountsRequirement(props: AccountsRequirementProps): ReactElement {
  const {
    requirement,
    requirementType,
    potentialShiftsAndFacilitiesCounts,
    documentsDropOffFlowId,
    qualification,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const {
    name,
    expiry: expirationDateOfRequirement,
    reqId: requirementId,
    requirementWorkflowType,
  } = requirement;

  const { actionIsRequired, icon } = REQUIREMENT_STATUS_DATA[requirementType];

  const isProfessionalReferenceRequirementAndStatusIsNotCompleted =
    requirementId === PROFESSIONAL_REFERENCE_REQUIREMENT_ID &&
    requirementType !== DocumentRequirementType.COMPLETED;

  const isExpiringSoon = getIsExpiringSoon(requirement, requirementType);

  const link = useGetDocumentDetailsRoute({
    shouldRouteToProfessionalReference: isProfessionalReferenceRequirementAndStatusIsNotCompleted,
    requirementId,
    documentRequirementType: requirementType,
  });

  useLogEffect(
    APP_V2_APP_EVENTS.REQUIREMENT_UNLOCKED_SHIFTS_TRACK_EVENT,
    {
      requirementId,
      documentRequirementType: requirementType,
      potentialShiftsCount: potentialShiftsAndFacilitiesCounts?.potentialShiftsCount,
      potentialFacilitiesCount: potentialShiftsAndFacilitiesCounts?.potentialFacilitiesCount,
    },
    { enabled: isDefined(potentialShiftsAndFacilitiesCounts) }
  );

  return (
    <AccountsRequirementCard
      title={name}
      actionIsRequired={actionIsRequired || isExpiringSoon}
      statusIcon={isExpiringSoon ? <ReportProblemIcon color="warning" /> : icon}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.DOCUMENTS_UPLOAD_FLOW_DOCUMENTS_PAGE_REQUIREMENT_CLICKED, {
          documentsDropOffFlowId,
          requirementId,
          requirementStatus: requirementType,
          requirementWorkflowType,
        });
        history.push(link, { returnLocation: location, documentsDropOffFlowId, qualification });
      }}
    >
      <>
        {isExpiringSoon &&
          isDefined(expirationDateOfRequirement) &&
          differenceInDays(parseISO(expirationDateOfRequirement), new Date()) > 0 && (
            <Text key="expiry-date-typography" variant="body2">
              {`Expires in ${pluralize(
                "day",
                differenceInDays(parseISO(expirationDateOfRequirement), new Date()),
                true
              )} (${formatDate(parseISO(expirationDateOfRequirement))})`}
            </Text>
          )}
        {requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES &&
          isDefined(potentialShiftsAndFacilitiesCounts) &&
          potentialShiftsAndFacilitiesCounts.potentialShiftsCount > 0 &&
          potentialShiftsAndFacilitiesCounts.potentialFacilitiesCount > 0 && (
            <Text
              key="potential-shift-count-typography"
              bold
              color={(theme) => theme.palette.success.main}
              variant="body2"
            >
              {`Access ${potentialShiftsAndFacilitiesCounts.potentialShiftsCount} more ${pluralize(
                "shift",
                potentialShiftsAndFacilitiesCounts.potentialShiftsCount
              )} at ${pluralize(
                "workplace",
                potentialShiftsAndFacilitiesCounts.potentialFacilitiesCount,
                true
              )}`}
            </Text>
          )}
        {requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES &&
          isNullOrUndefined(potentialShiftsAndFacilitiesCounts) && (
            <Skeleton aria-label="Loading Potential Shifts" />
          )}
      </>
    </AccountsRequirementCard>
  );
}
